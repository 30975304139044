import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { ReactSVG } from 'react-svg'
import './App.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Grid container spacing={24} alignItems="center" justify="space-around" alignContent="center" className="App-grid">
          <Grid item xs={12}>
            <LyricGrid />
          </Grid>
          <Grid item xs={12}>
           <Footer />
          </Grid>
        </Grid>
      </div>
    );
  }
}

function LyricGrid() {
  return(
    <Grid container spacing={0} alignItems="center" justify="space-around" alignContent="center" className="lyric-grid">
      <Grid item xs={12} className="image">
        <img src="/images/l1.jpg" />
      </Grid>
      <Grid item xs={12} className="image">
        <img src="/images/l2.jpg" />
      </Grid>
    </Grid>
  )
}

function Footer() {
  return (
    <Grid container spacing={0} alignItems="center" justify="center" alignContent="center" className="footer-grid">
      <Grid item xs={6} sm={3} className="footer-button-container">
        <FooterButton name="STREAM" url="https://ffm.to/k1xvldr"/>
      </Grid>
      <Grid item xs={6} sm={3} className="footer-button-container">
        <FooterButton name="ORDER" url="https://owlboyrecords.bigcartel.com/products"/>
      </Grid>
    </Grid>
  )
}

function FooterButton(props) {
  return (
    <div className={`footer-button footer-button-${props.name}`}>
      <a href={props.url} >
        <div className="button">
          <span>{props.name}</span>
        </div>
      </a>
    </div>
  )
}

function SocailIcon(props) {
  return (
    <div className="listen-icon">
      <a href={props.url} target="_blank" rel="noopener noreferrer">
        <ReactSVG src={`/images/${props.name}.svg`} className="icon"/>
      </a>
    </div>
  )
}

export default App;
